body {
    font-family: 'SimonKucher', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    min-height: 100%;
}

html {
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}

::-webkit-scrollbar {
    height: 8px;
    width: 11px;
}

::-webkit-scrollbar-thumb:horizontal{
    background: #e3e3e6;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: #e3e3e6;
    border-radius: 4px;
    /*border-right: 4px white solid;*/
}

p {
    margin: 0;
    padding: 0
}

li {
    list-style: none;
    padding: 0;
}

ul {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

* {
    box-sizing: border-box;
}

h2, h3 {
    margin: 0;
    font-weight: normal;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill{
    -webkit-text-fill-color: #333333 !important;
}